import React from "react"

import Layout from "../components/layout"
import { Formik, Form, Field, ErrorMessage } from "formik"
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'

class ContactmePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      displayForm: true,
      items: [],
    }
  }

  render() {
    var items = null
    if (this.state.items.length > 0) {
      items = this.state.items.map( item => (
        <div key={item} className="message">
          <h4>{item}</h4>
        </div>
      ))
    }

    return <Layout>
      <section className="content__body--contactme">
        <h1>Contact me</h1>
        <Formik
           initialValues={{ name: "", email: "", message: "" }}
           validate={values => {
             let errors = {};
             if (!values.name) {
               errors.name = 'Required';
             }

             if (!values.email) {
               errors.email = 'Required';
             }else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
               errors.email = 'Invalid email address';
             }

             return errors;
           }}
           onSubmit={(values, { setSubmitting }) => {
             setTimeout(() => {
               const formUrl = "https://docs.google.com/forms/d/e/1FAIpQLSfR9tYi6PT1JputpjG9GUYdHi4MXxs2iPUtd08sqSWtpDV-Nw/formResponse"
               const data = {
                 "entry.1951946931": values.name,
                 "entry.1114900198": values.email,
                 "entry.1328629480": values.message
               }
               var queryString = Object.keys(data).map((key) => {
                 return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
               }).join('&');

               fetch(`${formUrl}?${queryString}`, {
                 headers: {
                   'Content-Type': 'text/plain'
                 }
               })

               this.setState({displayForm: false})
               const message = `Thank you ${values.name} for contacting, I will be in touch as soon as I can!`
               this.setState({items: [message]})
               setSubmitting(false);

             }, 400);
           }}
        >
        {({ isSubmitting }) => (
          <Form className={(this.state.displayForm ? "gform" : "hide")}>
            <Field type="text" name="name" placeholder="name" required/>
            <ErrorMessage name="name" component="span" className="error"/>

            <Field type="email" name="email" placeholder="email" required/>
            <ErrorMessage name="email" component="span" className="error"/>

            <Field component="textarea" name="message" placeholder="message" required/>

            <input id="send" type="submit" value="Submit" disabled={isSubmitting}/>
          </Form>
        )}
        </Formik>
        <ReactCSSTransitionGroup
          transitionName="submit-message"
          transitionAppear={true}
          transitionAppearTimeout={700}
          transitionEnter={false}
          transitionLeave={false}>
          {items}
        </ReactCSSTransitionGroup>
      </section>
    </Layout>
  }
}

export default ContactmePage

